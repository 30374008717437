import React, { useState, useEffect } from 'react';

const HelloWorld = () => {
  const [message, setMessage] = useState('Loading...');
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMessage = async () => {
      try {
        const response = await fetch('https://senior-project-backend-gmak.onrender.com/hello');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setMessage(data.message);
      } catch (e) {
        console.error("There was a problem fetching the hello message:", e);
        setError("Failed to load message. Please try again later.");
      }
    };

    fetchMessage();
  }, []);

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="p-4 max-w-sm mx-auto bg-white rounded-xl shadow-md flex items-center space-x-4">
      <div className="text-xl font-medium text-black">{message}</div>
    </div>
  );
};

export default HelloWorld;