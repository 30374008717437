import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import HelloWorld from './components/HelloWorld';

const App = () => {
  return (
    <Router>
      <div>
        <nav className='navbar navbar-dark bg-primary'>
          <div className='container-fluid'>
            <a className='navbar-brand' href='/'>
              BoxedIn App
            </a>
          </div>
        </nav>

        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/hello" element={<HelloWorld />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;